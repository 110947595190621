import { useEventListener } from "@vueuse/core";
import { type Ref, ref, watch } from "vue";

type UseHorizontalScollBoundaries = {
  hasScrollReachedLeft: Ref<boolean>;
  hasScrollReachedRight: Ref<boolean>;
};

export function useHorizontalScrollBoundaries(table: Ref<HTMLElement>): UseHorizontalScollBoundaries {
  const hasScrollReachedLeft = ref(true);
  const hasScrollReachedRight = ref(false);
  let lastKnownScrollPosition = 0;

  const computeBoundaries = (scrollPos: number): void => {
    hasScrollReachedLeft.value = scrollPos === 0;
    hasScrollReachedRight.value = scrollPos + table.value.offsetWidth === table.value.scrollWidth;
  };

  watch(table, (dataTable) => {
    if (dataTable == null) {
      return;
    }

    computeBoundaries(0);
  });

  useEventListener(table, "scroll", () => {
    lastKnownScrollPosition = table.value.scrollLeft;
    computeBoundaries(lastKnownScrollPosition);
  });

  return {
    hasScrollReachedLeft,
    hasScrollReachedRight,
  };
}
